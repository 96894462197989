<template>
  <div></div>
</template>

<script>
import http from "@/http";
export default {
  created() {
    this.getStripe();
  },
  methods: {
    getStripe: function() {
      let stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
      let plan = JSON.parse(localStorage.getItem("plan"));
      plan.flavours = JSON.parse(localStorage.getItem("flavour"));
      let data = {
        session: plan.session,
        dryFoodFlavour: plan.flavours.dryFood,
        wetFoodFlavour: plan.flavours.wetFood
      };

      http
        .post("/pricing_confirm", data)
        .then(response => {
          let stripeSession = response.data.content[0].id;
          stripe
            .redirectToCheckout({
              sessionId: stripeSession
            })
            .then(function(result) {
              console.log(result);
            });
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
<style lang="css" scoped></style>
